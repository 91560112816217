import Image from 'next/image'; 

function LocationCard(props: {
  location: string;
  href: string;
  imgPath: string;
}) {
  return (
    <a href={props.href} className="relative cursor-pointer !text-white !no-underline">
      <div className=" w-[288px] md:w-[384px] h-[180px] md:h-[240px]">
        <Image
          src={props.imgPath}
          alt={props.location}
          width={384}
          height={240}
          quality={100}
          className=" object-cover w-[288px] md:w-[384px] h-[180px] md:h-[240px] rounded-[36px] md:rounded-[48px]"
        />
        <div
          className=" font-degular-display-semibold absolute inset-0 flex 
          size-full items-center justify-center bg-white/50 
          transition delay-100 duration-300 ease-in-out hover:opacity-0 text-black"
        >
          <span className='text-[24px] md:text-[32px] '>{props.location}</span>
        </div>
      </div>
    </a>
  );
}

export default LocationCard;
